import { FeatureSuggestion, Suggestion } from './search/types';
import {
  AutofillFeatureSuggestion,
  AutofillSuggestion
} from './autofill/types';

function featureToSuggestion(feature: FeatureSuggestion): Suggestion;

function featureToSuggestion(
  feature: AutofillFeatureSuggestion
): AutofillSuggestion;

/**
 * Utility function to convert the {@link FeatureSuggestion} properties to a
 * {@link Suggestion} object.
 */
function featureToSuggestion(
  feature: FeatureSuggestion | AutofillFeatureSuggestion
): Suggestion | Omit<AutofillSuggestion, 'original_search_text' | 'action'> {
  const { properties } = feature;
  return {
    ...properties
  };
}

export { featureToSuggestion };
