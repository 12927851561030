import { generateUUID } from './utils/uuid';

/**
 * A `SessionToken` object is a unique identifier that groups together `suggest` / `retrieve` calls as part of the
 * [Mapbox Search API](https://docs.mapbox.com/api/search/search/#retrieve-a-suggestion).
 *
 * Session tokens are used for [billing](https://docs.mapbox.com/api/search/search/#search-api-pricing) and
 * customer-accessible analytics.
 *
 * A [UUIDv4](https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random)) value is recommended,
 * and is generated if an `id` is not provided.
 *
 * Note that any method that accepts a `SessionToken` object as an argument or option
 * can also accept a unique `string` and will perform an implicit conversion.
 * This flexible type is documented as {@link SessionTokenLike}.
 *
 * @name SessionToken
 * @example
 * ```typescript
 * const token = new SessionToken();
 * console.log(token.id); // = I am a UUIDv4 value!
 * ```
 */
export class SessionToken {
  /**
   * The session token in string format.
   */
  readonly id: string;

  constructor(id?: string) {
    this.id = id ?? generateUUID();
  }

  /**
   * Returns the session token in string format.
   *
   * This is the same as calling `token.id`, and is okay to be used for serialization.
   */
  toString(): string {
    return this.id;
  }

  /**
   * Converts a string to a `SessionToken` object.
   *
   * If a `SessionToken` object is passed in, the function returns a copy.
   */
  static convert(token: SessionToken | string): SessionToken {
    return new SessionToken(
      token instanceof SessionToken ? token.id : token.toString()
    );
  }
}

/**
 * A {@link SessionToken} object or string representing a Mapbox Search API session token.
 *
 * It's recommended this value is a [UUIDv4](https://en.wikipedia.org/wiki/Universally_unique_identifier#Version_4_(random)) value.
 *
 * @typedef SessionTokenLike
 * @type {SessionToken | string}
 * @example
 * const v1 = new SessionToken();
 * const v2 = new SessionToken('f06e7531-6373-4d5a-8614-b6f313488050');
 * const v3 = 'f06e7531-6373-4d5a-8614-b6f313488050';
 */
export type SessionTokenLike = string | SessionToken;
