import {
  ENDPOINT_RETRIEVE,
  ENDPOINT_SUGGEST,
  FORWARD_URL,
  PERMANENT_FORWARD_URL,
  PERMANENT_REVERSE_URL,
  RETRIEVE_URL,
  REVERSE_URL,
  SUGGEST_URL
} from './constants';
import {
  AdministrativeUnitTypes,
  Suggestion,
  FeatureSuggestion
} from './types';

import { LngLat, LngLatLike } from '../LngLat';
import { LngLatBounds, LngLatBoundsLike } from '../LngLatBounds';
import { SessionToken, SessionTokenLike } from '../SessionToken';

import { handleNonOkRes } from '../MapboxError';
import { getFetch } from '../fetch';
import { queryParams } from '../utils/queryParams';

interface AccessTokenOptions {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;
}

interface FetchOptions {
  /**
   * If specified, the connected {@link AbortController} can be used to
   * abort the current network request(s).
   *
   * This mechanism works in the same way as the [`fetch` API](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch).
   *
   * Reference:
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal#examples
   */
  signal?: AbortSignal;
}

interface SessionTokenOptions {
  /**
   * A customer-provided session token value, which groups a series of requests
   * together for [billing purposes](https://docs.mapbox.com/api/search/search/#search-api-pricing).
   *
   * Reference:
   * https://docs.mapbox.com/api/search/search/#session-based-pricing
   */
  sessionToken: SessionTokenLike;
}

/**
 * @typedef Options
 */
export interface Options {
  /**
   * The [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag) to be returned.
   *
   * If not specified, `en` will be used.
   */
  language: string;
  /**
   * An [ISO 3166 alpha-2 country code](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) to be returned.
   *
   * If not specified, results will not be filtered by country.
   */
  country: string;

  /**
   * Limit results to only those contained within the supplied bounding box.
   */
  bbox: string | LngLatBoundsLike;
  /**
   * The number of results to return, up to `10`.
   */
  limit: string | number;
  /**
   * The navigation routing profile to use for distance/eta calculations.
   *
   * For distance calculations, both {@link Options#navigation_profile} and
   * {@link Options#origin} must be specified.
   *
   * For ETA calculations: {@link Options#navigation_profile},
   * {@link Options#origin}, and {@link Options#eta_type} must be specified.
   */
  navigation_profile?: 'driving' | 'walking' | 'cycling';
  /**
   * The location from which to calculate distance. **This parameter may incur additional latency.**
   *
   * When both {@link Options#proximity} and {@link Options#origin} are specified, `origin` is interpreted as the
   * target of a route, while `proximity` indicates the current user location.
   *
   * For distance calculations, both {@link Options#navigation_profile} and
   * {@link Options#origin} must be specified.
   *
   * For ETA calculations: {@link Options#navigation_profile},
   * {@link Options#origin}, and {@link Options#eta_type} must be specified.
   */
  origin: string | LngLatLike;
  /**
   * Bias the response to favor results that are closer to this location.
   *
   * When both {@link Options#proximity} and {@link Options#origin} are specified, `origin` is interpreted as the
   * target of a route, while `proximity` indicates the current user location.
   */
  proximity: string | LngLatLike;
  /**
   * Used to estimate the time of arrival from {@link Options#origin}. **This parameter may incur additional latency.**
   *
   * For ETA calculations: {@link Options#navigation_profile},
   * {@link Options#origin}, and {@link Options#eta_type} must be specified.
   */
  eta_type?: 'navigation';
  /**
   * Limit results to one or more types of features. If no types are specified, all possible types may be returned.
   *
   * Reference:
   * https://docs.mapbox.com/api/search/search/#administrative-unit-types
   */
  types?: string | Set<AdministrativeUnitTypes>;
}

interface PermanentOptions {
  /**
   * The permanent endpoints are used for use cases that require storing
   * position data. If 'true', the permanent endpoints will be used, which are
   * billed separately.
   *
   * If you're interested in using {@link PermanentOptions#permanent}, contact
   * [Mapbox sales](https://www.mapbox.com/contact/sales/).
   *
   * It's important to speak with an Account Manager on the Sales team prior to making requests
   * with {@link PermanentOptions#permanent} set to `true`, as unsuccessful requests
   * made by an account that does not have access to the endpoint may be billable.
   */
  permanent: boolean;
}

/**
 * @typedef SuggestionResponse
 */
export interface SuggestionResponse {
  /**
   * The attribution data for results.
   */
  attribution?: string;
  /**
   * The returned suggestion objects.
   *
   * @see {@link Suggestion}
   */
  suggestions: Suggestion[];
}

/**
 * @typedef RetrieveResponse
 */
export interface RetrieveResponse {
  type: 'FeatureCollection';
  /**
   * The attribution data for results.
   */
  attribution?: string;
  /**
   * The returned feature objects.
   *
   * @see {@link FeatureSuggestion}
   */
  features: FeatureSuggestion[];
}

/**
 * A `MapboxSearch` object is an application's main entrypoint to the [Mapbox Search API](https://docs.mapbox.com/api/search/search/).
 *
 * `MapboxSearch` is focused on the two-step, interactive search experience. These steps are:
 *   1. {@link MapboxSearch#suggest}: The user enters a search term, and a list of suggested results is returned with
 *      optional data such as: eta, distance calculations, etc.
 *   2. {@link MapboxSearch#retrieve}: The user selects a result from the list of suggested results, and the
 *     corresponding geographic coordinates are returned for displaying on a map or otherwise manipulating.
 *
 * A [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) is required to use `MapboxSearch`, and
 * other options may be specified either in the constructor or in the {@link MapboxSearch#suggest} call.
 *
 * @class MapboxSearch
 * @param {Options} [options]
 * @param {string} [options.accessToken]
 *
 * @example
 * ```typescript
 * const search = new MapboxSearch({ accessToken: 'pk.my-mapbox-access-token' });
 *
 * const sessionToken = new SessionToken();
 * const result = await search.suggest('Washington D.C.', { sessionToken });
 * if (result.suggestions.length === 0) return;
 *
 * const suggestion = result.suggestions[0];
 * if (search.canRetrieve(suggestion)) {
 *  const { features } = await search.retrieve(suggestion, { sessionToken });
 *  doSomethingWithCoordinates(features);
 * } else if (search.canSuggest(suggestion)) {
 *   // .. go through suggest flow again ..
 * }
 * ```
 */
export class MapboxSearch {
  static defaults: Partial<Options> = {
    language: 'en'
  };

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;

  /**
   * Any default options ({@link Options}) to be merged into options in the following methods:
   * - {@link MapboxSearch#suggest}
   * - {@link MapboxSearch#forward}
   * - {@link MapboxSearch#reverse}
   */
  defaults: Partial<Options>;

  constructor(options: Partial<AccessTokenOptions & Options> = {}) {
    const { accessToken, ...defaults } = options;

    this.accessToken = accessToken;

    // Assign defaults to this.defaults.
    this.defaults = {
      ...MapboxSearch.defaults,
      ...defaults
    };
  }

  /** @section {Interactive search} */

  /**
   * {@link MapboxSearch#suggest} is "part one" of the two-step interactive search experience, and includes
   * useful information such as: {@link Suggestion#feature_name}, {@link Suggestion#description}, and {@link Suggestion#maki}.
   *
   * Suggestion objects **do not include geographic coordinates**. To get the coordinates of the result, use {@link MapboxSearch#retrieve}.
   *
   * It may be useful to call {@link MapboxSearch#canRetrieve} before calling this method, as the suggestion may be a reference to
   * another suggest query. This can also be tested with {@link MapboxSearch#canSuggest}, and further calls to {@link MapboxSearch#suggest}.
   *
   * For tracking purposes, it is useful for any follow-up requests based on this suggestion to include same
   * {@link Suggestion#sessionToken} as the original request.
   *
   * If you'd like session tokens to be handled automatically, see {@link SearchSession}.
   *
   * @param {string} searchText
   * @param {Options} optionsArg
   * @param {SessionTokenLike} optionsArg.sessionToken
   * @param {AbortSignal} [optionsArg.signal]
   */
  async suggest(
    searchText: string,
    optionsArg: SessionTokenOptions & Partial<FetchOptions & Options>
  ): Promise<SuggestionResponse> {
    if (!searchText) {
      throw new Error('searchText is required');
    }

    const { sessionToken, signal } = optionsArg;

    const options = {
      ...this.defaults,
      ...optionsArg,
      sessionToken
    };

    if (options.eta_type && (!options.origin || !options.navigation_profile)) {
      throw new Error(
        'to provide eta estimate: eta, navigation_profile, and origin are required'
      );
    }
    if (options.origin && !options.navigation_profile) {
      throw new Error(
        'to provide distance estimate: both navigation_profile and origin are required'
      );
    }

    const url = new URL(`${SUGGEST_URL}/${encodeURIComponent(searchText)}`);
    url.search = this.#getQueryParams(options);

    const { fetch } = getFetch();
    const res = await fetch(url.toString(), {
      signal
    });

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as SuggestionResponse;
    return json;
  }

  /**
   * {@link MapboxSearch#retrieve} is "part two" of the two-step interactive search experience and includes
   * geographic coordinates in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * {@link suggestion} is usually a {@link Suggestion} returned from "part one," {@link MapboxSearch#suggest}.
   *
   * Multiple feature suggestions may be returned from a single search query, for example in an airport with
   * multiple terminals.
   *
   * **Legal terms:**
   *
   * Due to legal terms from our data sources, if the results are to be cached/stored in a customer database,
   * feature suggestions should come from the {@link MapboxSearch#forward} method
   * with {@link PermanentOptions#permanent} enabled.
   *
   * Otherwise, results should be used ephemerally and not persisted.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param {any} optionsArg
   * @param {SessionTokenLike} optionsArg.sessionToken
   * @param {AbortSignal} [optionsArg.signal]
   */
  async retrieve(
    suggestion: Suggestion,
    optionsArg: SessionTokenOptions & Partial<FetchOptions>
  ): Promise<RetrieveResponse> {
    if (!suggestion) {
      throw new Error('suggestion is required');
    }
    if (!this.canRetrieve(suggestion)) {
      throw new Error('suggestion cannot be retrieved');
    }

    const { sessionToken: sessionTokenLike, signal } = optionsArg;

    const sessionToken = SessionToken.convert(sessionTokenLike);

    const url = new URL(RETRIEVE_URL);
    url.search = queryParams({
      access_token: this.accessToken,
      session_token: sessionToken.id
    });

    const { fetch } = getFetch();
    const res = await fetch(url.toString(), {
      ...this.#getFetchInfo(suggestion),
      signal
    });

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as RetrieveResponse;
    return json;
  }

  /**
   * Returns true if {@link MapboxSearch#retrieve} can be called on this suggestion,
   * false otherwise.
   *
   * This indicates the [Mapbox Search API](https://docs.mapbox.com/api/search/search/) has geographic coordinates
   * for this suggestion.
   *
   * This method is mutually exclusive with {@link MapboxSearch#canSuggest}.
   */
  canRetrieve(suggestion: Suggestion): boolean {
    const action = suggestion.action;
    if (!action) {
      return false;
    }

    return action.method === 'POST' && action.endpoint === ENDPOINT_RETRIEVE;
  }

  /**
   * Returns true if {@link MapboxSearch#suggest} can be called on this suggestion,
   * false otherwise.
   *
   * This indicates the [Mapbox Search API](https://docs.mapbox.com/api/search/search/) wants to do another
   * suggestion search on this result, and does not have geographic coordinates.
   *
   * This method is mutually exclusive with {@link MapboxSearch#canRetrieve}.
   */
  canSuggest(suggestion: Suggestion): boolean {
    const action = suggestion.action;
    if (!action) {
      return false;
    }

    return action.method === 'POST' && action.endpoint === ENDPOINT_SUGGEST;
  }

  /** @section {Programmatic search} */

  /**
   * {@link MapboxSearch#forward} is our programmatic one-step search experience and includes
   * geographic coordinates in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * Multiple feature suggestions may be returned from a single search query, for example in an airport with
   * multiple terminals.
   *
   * **Legal terms:**
   *
   * Due to legal terms from our data sources, if the results are to be cached/stored in a customer database,
   * {@link PermanentOptions#permanent} should be enabled. This requires contacting Mapbox support.
   *
   * Otherwise, results should be used ephemerally and not persisted.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param {Options} optionsArg
   * @param {AbortSignal} [optionsArg.signal]
   * @param {boolean} [optionsArg.permanent]
   */
  async forward(
    searchText: string,
    optionsArg: Partial<FetchOptions & Options & PermanentOptions> = {}
  ): Promise<RetrieveResponse> {
    if (!searchText) {
      throw new Error('searchText is required');
    }

    const options = {
      ...this.defaults,
      ...optionsArg
    };

    const baseUrl = options.permanent ? PERMANENT_FORWARD_URL : FORWARD_URL;
    const url = new URL(`${baseUrl}/${encodeURIComponent(searchText)}`);
    url.search = this.#getQueryParams(options);

    const { fetch } = getFetch();
    const res = await fetch(url.toString(), {
      signal: options.signal
    });

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as RetrieveResponse;
    return json;
  }

  /**
   * {@link MapboxSearch#reverse} allows you to look up a geographic coordinate pair
   * and returns the feature(s) in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * Multiple feature suggestions may be returned from a single search query, for example in an airport with
   * multiple terminals.
   *
   * **Legal terms:**
   *
   * Due to legal terms from our data sources, if the results are to be cached/stored in a customer database,
   * {@link PermanentOptions#permanent} should be enabled. This requires contacting Mapbox support.
   *
   * Otherwise, results should be used ephemerally and not persisted.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param lngLat - Either a {@link LngLatLike} object or string in 'lng,lat' comma-separated format.
   * @param {Options} optionsArg
   * @param {AbortSignal} [optionsArg.signal]
   * @param {boolean} [optionsArg.permanent]
   */
  async reverse(
    lngLat: string | LngLatLike,
    optionsArg: Partial<FetchOptions & Options & PermanentOptions> = {}
  ): Promise<RetrieveResponse> {
    if (!lngLat) {
      throw new Error('lngLat is required');
    }

    const options = {
      ...this.defaults,
      ...optionsArg
    };

    const searchText =
      typeof lngLat === 'string'
        ? lngLat
        : LngLat.convert(lngLat).toArray().join(',');

    const baseUrl = options.permanent ? PERMANENT_REVERSE_URL : REVERSE_URL;
    const url = new URL(`${baseUrl}/${encodeURIComponent(searchText)}`);
    url.search = queryParams(
      {
        access_token: this.accessToken,
        language: options.language,
        limit: options.limit
      },
      options.types && {
        types:
          typeof options.types === 'string'
            ? options.types
            : [...options.types].join(',')
      }
    );

    const { fetch } = getFetch();
    const res = await fetch(url.toString(), {
      signal: options.signal
    });

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as RetrieveResponse;
    return json;
  }

  /**
   * Returns the query parameters used by {@link MapboxSearch#suggest} and
   * {@link MapboxSearch#forward}.
   */
  #getQueryParams(options: Partial<Options & SessionTokenOptions>): string {
    return queryParams(
      {
        access_token: this.accessToken,
        language: options.language,
        country: options.country,
        limit: options.limit,
        navigation_profile: options.navigation_profile,
        eta_type: options.eta_type
      },
      options.sessionToken && {
        session_token: SessionToken.convert(options.sessionToken).id
      },
      options.origin && {
        origin:
          typeof options.origin === 'string'
            ? options.origin
            : LngLat.convert(options.origin).toArray().join(',')
      },
      options.proximity && {
        proximity:
          typeof options.proximity === 'string'
            ? options.proximity
            : LngLat.convert(options.proximity).toArray().join(',')
      },
      options.bbox && {
        bbox:
          typeof options.bbox === 'string'
            ? options.bbox
            : LngLatBounds.convert(options.bbox).toFlatArray().join(',')
      },
      options.types && {
        types:
          typeof options.types === 'string'
            ? options.types
            : [...options.types].join(',')
      }
    );
  }

  /**
   * Gets a partial fetch request from this suggestion's action.
   */
  #getFetchInfo(suggestion: Suggestion): Partial<RequestInit> {
    if (!this.canRetrieve(suggestion) && !this.canSuggest(suggestion)) {
      throw new Error('Suggestion cannot be retrieved or suggested');
    }

    const action = suggestion.action;
    const body = JSON.stringify(action.body);

    return {
      method: action.method,
      body,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': body.length.toString()
      }
    };
  }
}
