import { VALIDATE_URL } from './constants';
import { ValidateFeature } from './types';

import { LngLat, LngLatLike } from '../LngLat';
import { LngLatBounds, LngLatBoundsLike } from '../LngLatBounds';
import { SessionToken, SessionTokenLike } from '../SessionToken';

import { handleNonOkRes } from '../MapboxError';
import { getFetch } from '../fetch';
import { queryParams } from '../utils/queryParams';

interface AccessTokenOptions {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;
}

interface FetchOptions {
  /**
   * If specified, the connected {@link AbortController} can be used to
   * abort the current network request(s).
   *
   * This mechanism intentionally works in the same way as the
   * [`fetch` API](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch).
   *
   * Reference:
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal#examples
   */
  signal?: AbortSignal;
}

interface SessionTokenOptions {
  /**
   * A customer-provided session token value, which groups a series of requests together for [billing purposes](https://docs.mapbox.com/api/search/search/#search-api-pricing).
   *
   * Reference:
   * https://docs.mapbox.com/api/search/search/#session-based-pricing
   */
  sessionToken: SessionTokenLike;
}

/**
 * @typedef ValidateOptions
 */
export interface ValidateOptions {
  /**
   * The [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag) to be returned.
   *
   * If not specified, `en` will be used.
   */
  language: string;
  /**
   * An [ISO 3166 alpha-2 country code](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) to be returned.
   *
   * If not specified, results will not be filtered by country.
   */
  country: string;
  /**
   * Limit results to only those contained within the supplied bounding box.
   */
  bbox: string | LngLatBoundsLike;
  /**
   * Bias the response to favor results that are closer to this location.
   *
   * When both {@link ValidateOptions#proximity} and {@link ValidateOptions#origin} are specified, `origin` is interpreted as the
   * target of a route, while `proximity` indicates the current user location.
   */
  proximity: string | LngLatLike;
}

/**
 * @typedef ValidateResponse
 */
export interface ValidateResponse {
  type: 'FeatureCollection';
  /**
   * The attribution data for results.
   */
  attribution?: string;
  /**
   * The returned feature objects.
   *
   * @see {@link FeatureSuggestion}
   */
  features: ValidateFeature[];
}

/**
 * A `MapboxValidate` object is an application's main entrypoint to the
 * Mapbox Validate API. The Mapbox Validate API is an API similar to {@link MapboxAutofill},
 * but targeted at checking **address**.
 *
 * A [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) is required to use `MapboxValidate`, and
 * other options may be specified either in the constructor or in the {@link MapboxValidate#validate} call.
 *
 * @class MapboxValidate
 * @param {ValidateOptions} [options]
 * @param {string} [options.accessToken]
 */
export class MapboxValidate {
  static defaults: Partial<ValidateOptions> = {
    language: 'en',
    proximity: 'ip'
  };

  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;

  /**
   * Any default options ({@link ValidateOptions}) to be merged into options in the following methods:
   * - {@link ValidateOptions#validate}
   *
   * @type {ValidateOptions}
   */
  defaults: Partial<ValidateOptions>;

  constructor(options: Partial<AccessTokenOptions & ValidateOptions> = {}) {
    const { accessToken, ...defaults } = options;

    this.accessToken = accessToken;

    // Assign defaults to this.defaults.
    this.defaults = {
      ...MapboxValidate.defaults,
      ...defaults
    };
  }

  /** @section {Methods} */

  /**
   * {@link MapboxValidate#validate} allows you to validate an address
   * and returns the feature(s) in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * **Legal terms:**
   *
   * Geographic coordinates should be used ephemerally and not persisted.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param {String} searchText
   * @param {SessionTokenLike} optionsArg.sessionToken
   * @param {AbortSignal} [optionsArg.signal]
   */
  async validate(
    searchText: string,
    optionsArg: SessionTokenOptions & Partial<FetchOptions>
  ): Promise<ValidateResponse> {
    if (!searchText) {
      throw new Error('searchText is required');
    }

    const { sessionToken, signal } = optionsArg;

    const options = {
      ...this.defaults,
      ...optionsArg,
      sessionToken
    };

    const url = new URL(`${VALIDATE_URL}/${encodeURIComponent(searchText)}`);
    url.search = this.#getQueryParams(options);

    const { fetch } = getFetch();
    const res = await fetch(url.toString(), {
      signal
    });

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as ValidateResponse;

    if (json.features.length > 0) {
      json.features = [json.features[0]];
    }

    return json;
  }

  /**
   * Returns the query parameters used by {@link MapboxValidate#validate}
   */
  #getQueryParams(
    options: Partial<ValidateOptions & SessionTokenOptions>
  ): string {
    return queryParams(
      {
        access_token: this.accessToken,
        language: options.language,
        country: options.country
      },
      options.sessionToken && {
        session_token: SessionToken.convert(options.sessionToken).id
      },
      options.proximity && {
        proximity:
          typeof options.proximity === 'string'
            ? options.proximity
            : LngLat.convert(options.proximity).toArray().join(',')
      },
      options.bbox && {
        bbox:
          typeof options.bbox === 'string'
            ? options.bbox
            : LngLatBounds.convert(options.bbox).toFlatArray().join(',')
      }
    );
  }
}
