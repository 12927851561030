export const SEARCH_URL = `https://api.mapbox.com/search/v1`;

export const ENDPOINT_SUGGEST = 'suggest';
export const ENDPOINT_RETRIEVE = 'retrieve';
export const ENDPOINT_FORWARD = 'forward';
export const ENDPOINT_REVERSE = 'reverse';

export const SUGGEST_URL = `${SEARCH_URL}/${ENDPOINT_SUGGEST}`;
export const RETRIEVE_URL = `${SEARCH_URL}/${ENDPOINT_RETRIEVE}`;

export const FORWARD_URL = `${SEARCH_URL}/${ENDPOINT_FORWARD}`;
export const PERMANENT_FORWARD_URL = `${SEARCH_URL}/permanent/${ENDPOINT_FORWARD}`;

export const REVERSE_URL = `${SEARCH_URL}/${ENDPOINT_REVERSE}`;
export const PERMANENT_REVERSE_URL = `${SEARCH_URL}/permanent/${ENDPOINT_REVERSE}`;
