import { getFetch } from '../fetch';
import { LngLat, LngLatLike } from '../LngLat';
import { LngLatBounds, LngLatBoundsLike } from '../LngLatBounds';
import { handleNonOkRes } from '../MapboxError';
import { queryParams } from '../utils/queryParams';
import { PERMANENT_URL, TEMP_URL } from './constants';
import { DataTypes, GeocodeFeature } from './types';

interface AccessTokenOptions {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;
}

interface FetchOptions {
  /**
   * If specified, the connected {@link AbortController} can be used to
   * abort the current network request(s).
   *
   * This mechanism intentionally works in the same way as the
   * [`fetch` API](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API#aborting_a_fetch).
   *
   * Reference:
   * https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal#examples
   */
  signal?: AbortSignal;
}

/**
 * @typedef GeocodeOptions
 */
export interface GeocodeOptions {
  /**
   * When autocomplete is enabled, results will be included that start with the requested string, rather than just responses that match it exactly.
   *
   * Defaults to true.
   */
  autocomplete: boolean;
  /**
   * Limit results to only those contained within the supplied bounding box.
   */
  bbox: string | LngLatBoundsLike;
  /**
   * Limit results to one or more countries. Permitted values are [ISO 3166 alpha 2](https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2) country codes separated by commas.
   */
  country: string;
  /**
   * Specify whether the Geocoding API should attempt approximate, as well as exact, matching when performing searches.
   *
   * Defaults to true.
   */
  fuzzyMatch: boolean;
  /**
   * An [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag) that controls the language of the text supplied in responses, and also affects result scoring.
   */
  language: string;
  /**
   * The number of results to return, up to `10`.
   *
   * Defaults to 5.
   */
  limit: number;
  /**
   * Bias the response to favor results that are closer to this location.
   *
   * Provided as two comma-separated coordinates in longitude,latitude order, or the string `ip` to bias based on reverse IP lookup.
   */
  proximity: string | LngLatLike;
  /**
   * Specify whether to request additional metadata about the recommended navigation destination corresponding to the feature (`true`) or not (`false`, default). Only applicable for address features.
   */
  routing: boolean;
  /**
   * Filter results to include only a subset (one or more) of the available feature types. Multiple options can be comma-separated.
   */
  types: string | Set<DataTypes>;
  /**
   * Available worldviews are: `cn`, `in`, `jp`, `us`. If a worldview is not set, `us` worldview boundaries will be returned.
   */
  worldview: string;
}

interface PermanentOptions {
  /**
   * Permanent geocodes are used for use cases that require storing
   * position data. If 'true', the permanent endpoints will be used, which are
   * billed separately.
   *
   * If you're interested in using {@link PermanentOptions#permanent}, contact
   * [Mapbox sales](https://www.mapbox.com/contact/sales/).
   *
   * It's important to speak with an Account Manager on the Sales team prior to making requests
   * with {@link PermanentOptions#permanent} set to `true`, as unsuccessful requests
   * made by an account that does not have access to the endpoint may be billable.
   */
  permanent: boolean;
}

/**
 * A `GeocodeResponse` object represents a returned data object from the [Mapbox Geocoding API](https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object).
 *
 * @typedef GeocodeResponse
 */
export interface GeocodeResponse {
  /**
   * `"FeatureCollection"`, a GeoJSON type from the [GeoJSON specification](https://tools.ietf.org/html/rfc7946).
   */
  type: 'FeatureCollection';
  /**
   * Forward geocodes: An array of space and punctuation-separated strings from the original query.
   *
   * Reverse geocodes: An array containing the coordinates being queried.
   */
  query: string[];
  /**
   * The returned feature objects.
   *
   * @see {@link GeocodeFeature}
   */
  features: GeocodeFeature[];
  /**
   * Attributes the results of the Mapbox Geocoding API to Mapbox.
   */
  attribution: string;
}

/**
 * A `MapboxGeocode` object is an application's main entrypoint to the [Mapbox Geocoding API](https://docs.mapbox.com/api/search/geocoding/).
 * The Geocoding API allows forward (location to coordinates) and reverse (coordinates to location) queries, enabled by corresponding
 * methods from the `MapboxGeocode` object.
 *
 * A [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) is required to use `MapboxGeocode`, and
 * other options may be specified either in the constructor or in the {@link MapboxGeocode#forward} or {@link MapboxGeocode#reverse} calls.
 *
 * @class MapboxGeocode
 *
 * @example
 * ```typescript
 * const geocode = new MapboxGeocode({ accessToken: 'pk.my-mapbox-access-token' });
 *
 * const results = await geocode.forward('Washington D.C.');
 * if (results.features.length === 0) return;
 *
 * const feature = results.features[0];
 * doSomethingWithCoordinates(feature);
 * ```
 */
export class MapboxGeocode {
  /**
   * The [Mapbox access token](https://docs.mapbox.com/help/glossary/access-token/) to use for all requests.
   */
  accessToken: string;

  /**
   * Any default options ({@link GeocodeOptions}) to be merged into options in the following methods:
   * - {@link MapboxGeocode#forward}
   * - {@link MapboxGeocode#reverse}
   */
  defaults: Partial<GeocodeOptions>;

  constructor(options: Partial<AccessTokenOptions & GeocodeOptions> = {}) {
    const { accessToken, ...defaults } = options;

    this.accessToken = accessToken;

    // Assign defaults to this.defaults.
    this.defaults = {
      ...defaults
    };
  }

  /** @section {Methods} */

  /**
   * {@link MapboxGeocode#forward} allows you to look up a single location by name
   * and returns the feature(s) and corresponding geographic coordinates in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * **Legal terms:**
   *
   * Due to legal terms from our data sources, geographic coordinates should be used ephemerally and not persisted.
   * If the results are to be cached/stored in a customer database,
   * calls to {@link MapboxGeocode#forward} should specify `permanent: true` within the method options argument.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param {String} searchText
   * @param {GeocodeOptions} [optionsArg]
   * @param {AbortSignal} [optionsArg.signal]
   *
   * @example
   * ```typescript
   * const results = await geocode.forward('Washington D.C.');
   * if (results.features.length === 0) return;
   *
   * const feature = results.features[0];
   * doSomethingWithCoordinates(feature);
   * ```
   */
  async forward(
    searchText: string,
    optionsArg?: Partial<FetchOptions & GeocodeOptions & PermanentOptions>
  ): Promise<GeocodeResponse> {
    if (!searchText) {
      throw new Error('searchText is required');
    }

    let signal: AbortSignal;
    if (optionsArg) {
      ({ signal } = optionsArg);
    }

    const options = {
      ...this.defaults,
      ...optionsArg
    };

    const baseUrl = options.permanent ? PERMANENT_URL : TEMP_URL;
    const url = new URL(`${baseUrl}/${encodeURIComponent(searchText)}.json`);
    url.search = this.#getQueryParams(options);

    const { fetch } = getFetch();
    const fetchInit = signal ? { signal } : {};
    const res = await fetch(url.toString(), fetchInit);

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as GeocodeResponse;
    return json;
  }

  /**
   * {@link MapboxGeocode#reverse} allows you to look up a single pair of coordinates and returns the
   * geographic feature or features that exist at that location in [GeoJSON](https://docs.mapbox.com/help/glossary/geojson/) format.
   *
   * **Legal terms:**
   *
   * Due to legal terms from our data sources, geographic coordinates should be used ephemerally and not persisted.
   * If the results are to be cached/stored in a customer database,
   * calls to {@link MapboxGeocode#reverse} should specify `permanent: true` within the method options argument.
   *
   * This permanent policy is consistent with the [Mapbox Terms of Service](https://www.mapbox.com/tos/) and failure to comply
   * may result in modified or discontinued service.
   *
   * Additionally, the [Mapbox Terms of Service](https://www.mapbox.com/tos/) states any rendering of a feature suggestion
   * must be using Mapbox map services (for example, displaying results on Google Maps or MapKit JS is not allowed).
   *
   * **Disclaimer:**
   *
   * The failure of Mapbox to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.
   *
   * @param {String} searchText
   * @param {GeocodeOptions} [optionsArg]
   * @param {AbortSignal} [optionsArg.signal]
   *
   * @example
   * ```typescript
   * const results = await geocode.reverse({ lat: 40.7736, lng: -73.9749 });
   * if (results.features.length === 0) return;
   *
   * const feature = results.features[0];
   * doSomethingWithFeature(feature);
   * ```
   */
  async reverse(
    lngLat: string | LngLatLike,
    optionsArg?: Partial<FetchOptions & GeocodeOptions & PermanentOptions>
  ): Promise<GeocodeResponse> {
    if (!lngLat) {
      throw new Error('lngLat is required');
    }

    let signal: AbortSignal;
    if (optionsArg) {
      ({ signal } = optionsArg);
    }

    const options = {
      ...this.defaults,
      ...optionsArg
    };

    const searchText =
      typeof lngLat === 'string'
        ? lngLat
        : LngLat.convert(lngLat).toArray().join(',');
    const baseUrl = options.permanent ? PERMANENT_URL : TEMP_URL;
    const url = new URL(`${baseUrl}/${encodeURIComponent(searchText)}.json`);
    url.search = this.#getQueryParams(options, true);

    const { fetch } = getFetch();
    const fetchInit = signal ? { signal } : {};
    const res = await fetch(url.toString(), fetchInit);

    // Throw custom error if status code is not 200.
    await handleNonOkRes(res);

    const json = (await res.json()) as GeocodeResponse;
    return json;
  }

  /**
   * Returns the query parameters used by {@link <MapboxGeocode>#forward} and {@link <MapboxGeocode>#reverse}
   */
  #getQueryParams(options: Partial<GeocodeOptions>, isReverse = false): string {
    // Remove query parameters that don't apply to forward or reverse
    if (isReverse) {
      ['proximity', 'autocomplete', 'fuzzyMatch', 'bbox'].forEach((key) => {
        if (key in options) {
          delete options[key];
        }
      });
    }
    return queryParams(
      {
        access_token: this.accessToken,
        language: options.language,
        country: options.country,
        limit: options.limit,
        autocomplete: options.autocomplete,
        fuzzyMatch: options.fuzzyMatch,
        routing: options.routing,
        worldview: options.worldview
      },
      options.proximity && {
        proximity:
          typeof options.proximity === 'string'
            ? options.proximity
            : LngLat.convert(options.proximity).toArray().join(',')
      },
      options.bbox && {
        bbox:
          typeof options.bbox === 'string'
            ? options.bbox
            : LngLatBounds.convert(options.bbox).toFlatArray().join(',')
      },
      options.types && {
        types:
          typeof options.types === 'string'
            ? options.types
            : [...options.types].join(',')
      }
    );
  }
}
